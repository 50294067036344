@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@media (min-width:769px) {
  .module-container h1 {
    font-size: 4.5rem;
  }

  .module-container2 h1{
    font-size: 4.5rem;
  }
  
}

@media (max-width: 768px) {
  .module-container h1 {
    font-size: 3.3rem;
  }
  .module-container2 h1 {
    font-size: 3.3rem;
  }
  .footer-content{
    display: flex;
    flex-direction: column;
  }
  .footer-content-main{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 425px) {
  .module-container h1 {
    font-size: 3.3rem;
  }
  .module-container2 h1 {
    font-size: 3.3rem;
  }
  .footer-content{
    display: flex;
    flex-direction: column;
  }
}

body{
  top: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto;
  overflow-x: hidden;
}

.App{
  width: 100vw;
  height: 100vh;
  font-family: Roboto;
  position: relative;
  top: 88px;
}

/* .home-container{
  width: 100vw;
  height: 100vh;
} */



/*NAVBAR*/

.hamburger-navbar{
  font-family: Roboto;
}

.navbar-container{
  padding: 10px;
  padding-right: 30px;
  display: flex;
  position: fixed;
  align-items: center;
  /* padding-left: 50px; */
  background-color: white;
  z-index: 100000000;
  width: 100vw;
  
}

.navbar-main{
  z-index: 10000;
}

.navbar-container-a a{
  text-decoration: none;
  color: black;
}
.nav-hover{
  text-decoration: none;
  color: black;
}

.nav-hover:hover{
 color: #F6A200;
}

.nav-hover2{
top: -15px;
  width: 50px;
  height: 250px;
  position: absolute;
  z-index: 1;
  text-decoration: none;
  color: black;
}


.nav-hover2:hover{
 color: #F6A200;
}

/*test */
.dropdownmain {
  position: relative;
  display: inline-block;
  text-decoration: none!important;
  color: black;
}

.dropdown-contentmain {
  display:none;
  position: absolute;
  color: black;
  background-color: white;
  min-width: 160px;
  width: 250px;

  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  text-decoration: none!important;
  color: black;
  left: -5px;
  font-family: Roboto;
}

.dropdownmain:hover .dropdown-contentmain {
  display: block;
}
.dropdown-testmain{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-decoration: none!important;
  color: black;
  font-family: Roboto;
}
.dropdownmain{
  font-family: Roboto;
}

.dropdownmain a{
  text-decoration: none!important;
  color: black;
  font-family: Roboto;
  font-size: 19px;
}

.dropdownmain a:hover{
  text-decoration: none!important;
  color: #F6A400;
 
}
.nav-bar-final2 ul li{
  text-decoration: none!important;
}


ul{ list-style-type: none; } 
li{ text-decoration: none;}


.tooltiptext {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: black;

}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}


/* sad*/


.right-nav{
  display: flex;
  gap: 10px;
}
.mini-box{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #F6A200;
  color: white;
  border-radius: 50%;
}
.mini-box a {
  text-decoration: none;
  color: white;
}

.facebookstyle{
  background-color:white;
  color: #F6A200;
  border-radius: 50%;
  font-size: 1.5rem;
  /* border: 1px solid #F6A400; */
  text-decoration: none;
  
}

.facebook{
  background-color: white;
  border-radius: 50%;
  color: #F6A400;
  
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display:none;
  position: absolute;
  padding-top: 5px;
  padding-bottom: 5px;
  z-index: 1;
}
.dropdown-lang span {
  cursor: pointer;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-our{
  position: relative;
  display: inline-block;
}


.dropdown-our{
  position: absolute;
  left: 0;
  top: 85px;
  width: 100vw;
  height: 35vh;
  background-color: white;
  display: flex;
  justify-content: space-around;


}
.dropdown-our a {
  text-decoration: none;
  color: black;
  padding: 0.2rem;
}
.dropdown-our a:hover {
  text-decoration: none;
  color: #F6A200;
  font-weight: bold;
}


.nav-link a:hover{
  color: #F6A200;
}

.small, small:hover{
  color: #F6A200;
}

.searchnav {
  width: 230px;
  margin-right: 15px;
}



/* */
/* .app-main{
  position: relative;
  top:85px;
} */
.App{
  position: relative;
}

/* .upcoming-box{
  background-color: #032E63;
  width: 558px;
  height: 558px;
  margin-bottom: 25px;
} */

.container-lg{
  /* background-color:#032E63; */
  max-width: 1620px;
}
.fs-2{
  color: #FEE4BE;
}

.footer-container{
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #F6A200;
  color: white;
}
.footer-container a{
  text-decoration: none;
  color: white;
}

.footer-container a:hover{
  color: white;
  text-decoration: underline;
}


.about-container{
  padding-top: 127px;
  padding-bottom: 129px;
  background-color: blue;
  /* background-image: url("../public/Rectangle 18.png"); */

}

/* .home-container{
  padding-top: 150px;
  padding-bottom: 150px;
  background-image: url("../public/toppic.png");
  color: white;
} */

/* .home-container{
  width: 1920px;
  height: 800px;
} */

/* .home-container-main-two{
  width: 500px;
} */

.contactus-container{
  background-color: #032E63;
  color: white;
  padding-top: 100px;
  padding-bottom: 100px;
}
.contactus-container a{
  text-decoration: none;
  color: #F6A200;
}

.contactus-container a:hover{
  color: #F6A200;
}

.protips-container{
color: #F6A200;
}

.about-container{
  color: white;
}

/* .ourmiss-container img{
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

/* Homepage header */

.module-main{
  height: 800px;
  padding-top: 140px;
  padding-bottom: 100px;
background-size: cover;
background-position: center top;
color: white;
}

.module-main h1{
  font-size: 1.4rem;
}

.module-main p{
  margin-top: 100px;
  font-size: 25px;
}
/* Homepage header end */

/* Kegon container */

.module-container{
  color: white;
  padding-top: 100px;
  padding-bottom: 100px;
}

/* .module-container h1{
  font-size: 4.5rem;
  font-weight: bold;
} */

.module-container2 h1 {

  font-weight: bold;
  color: #F6A400;
}

.module-container p{
  font-size: 20px;
}

.module-container2{
  color: white;
  padding-top: 100px;
  padding-bottom: 100px;
}

.module-container2 h1 {
  font-weight: bold;
}

.module-container2 p{
  font-size: 20px;
}

/* .kegon-container .container{
  position: absolute;
  z-index: 1;
} */


/* Kegon container */

.contactus-container input{
  background: transparent;
  border: 0;
  outline: 0;
  /* border-bottom: 1px solid white; */
}

.contactus-container input:focus{
  background:transparent;
  border: none;
    border-width: 0 0 2px;
    border-color:white;
    font-family: Roboto;
  }

  .contactus-container textarea{

  background: transparent;
  border: 0;
  outline: 0;

  /* border-bottom: 1px solid white; */
}
  .contactus-container textarea:focus{
    background:transparent;
    border: none;
      border-width: 0 0 2px;
      border-color:white
    }
.contactus-container input::placeholder{
  color: white;
  font-family: Roboto;
}
    .contactus-container textarea::placeholder{
      display: flex;
      font-family: Roboto;
      align-items: flex-end;
      justify-content: end;
      color: white;
    }

/* .test input[type="checkbox"]{
  display: none;
} */

/* .test{
  display: flex;
  align-items: center;

}
.test p{
  display: flex;
  align-items: flex-end;
} */
.protips-container{
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.propicture{
  position: absolute;
 margin-left: -5vh;
 margin-top: -2vh;
  width: 15vh;
  height: 15vh;
  z-index: -1;
  
}

.button-book{
  text-decoration: none;
  background-color: #FEE4BE;
  color: black;
  border-radius: 5rem;
  border: none;
  padding: 5px 40px 5px 40px;
}

.button-book:hover{
  background-color: #98C21D;
  color: white;
}
.button-book a:hover{
  background-color: #98C21D;
  color: white;
}

.button-book2{
  color: black;
  background-color: #D1D4D5;
  border-radius: 5rem;
  border: none;
  padding: 5px 40px 5px 40px;
}
.button-book2:hover{
  background-color: #F6A200;
  color: white;
}


.elek{
  display: flex;
}



.ourcustomer-container{
  background-color: #E4E5E6;
  padding-top: 67px;
  padding-bottom: 136px;
}

/* upcoming */

.upcoming-box{
  background-color: #F6A400;
  ;
}
.upcoming2-box{
  background-color: #F6A400;
}



.flip-card {
  background-color: transparent;
  height: 558px;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}


.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 1s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0);
}


.flip-card-front {
  filter: brightness(0.8) contrast(1.2) grayscale(0.07) hue-rotate(28deg) saturate(2.1) opacity(0.68) sepia(0.39);
}

.flip-card-back {
  -webkit-transform-style: preserve-3d;
  transform: rotateY(180deg);
}

.contact{
  position: relative;

}

.contact .contact-number {
  opacity: 0;
  position: absolute;
  transition-duration: .3s;
}
.contact:hover .contact-text {
  opacity: 0;
  transition-duration: .3s;
  
}

.contact:hover .contact-number {
  opacity: 1;
  transition-duration: .3s;
}
.contact-number,
.contact-text {
  position: absolute;
  width: 100%;
  height: 100%;
}

/*   upcomingend */

/* .test{
  background-color: #032E63;
} */
.yellow{
  color: #FEE4BE;
}
/* .testelek{
  left: 0;
  
  width: 100vw;
  height: 25vh;
} */




.contact-us-understand{
  accent-color: #F6A200;
}
.contact-us-understand a{
  color: #F6A200;
  text-decoration: none;
}
.contact-us-understand a:hover{
  color: #F6A200;
  text-decoration: none;
}

.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  display: none;
}


.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
 display: none;
}


/* swiper */


/* @media screen and (min-width: 640px) {
  .swiper-container {
    width: 1200px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
} */

/* Training container */

.training-container h1{
  color: #F6A400;
}

/* Training container end */

/*SAFe */




/*SAFe end */

/*Didyou */
.didyou-container h1{
  color: #F6A400;
}
.didyou-p{
  color: #F6A400;
}
.didpicture{
  position: absolute;
 margin-left: -30px;
 margin-top: -320px;
   width: 70px; 
  height: 70px; 
  z-index: -1;
  
}

/*Module  */

.module-box{
  background-color: #FEE4BE;
}
.module-box-open{
  background-color: #F6A400;
  color: white;
}

.module-box-main{
  color: #F6A400;
  background-color: #FEE4BE;
  /* transition: all 0.1s ease-in-out; */
}

/* Reszletek */
.reszletek-footer{
  background-color: #F6A400;
  color: white;
}

.reszletek-left{
  border-top: 4px solid  #F6A400;
  color: #F6A200;
}
.reszletek-center{
  border-top: 4px solid  #98C21D;
  color: #98C21D;
}
.reszletek-right{
  border-top: 4px solid  #032E63;
  color: #032E63;
}

.reszletek-text p{
  padding: 0;
}


/* Reszletek end */


/* Calendar */

.check-hover{
  color: #F6A400;
  background-color: white;
  border-radius: 50%;
  width: 2rem;
}
.check-hover:hover{
 color: white;
 background-color: #98C21D;
}
.calendar-img{
  width: 80px;
}

/* Calendarend */

/* We are the the Kegon */
.wearethe-container{
padding-top: 150px;
background-position: center;
  /* filter: brightness(0.8) contrast(1.2) grayscale(0.07) hue-rotate(28deg) saturate(2.1) opacity(0.78) sepia(0.39); */
}

.linkedin-circle{
  width: 2rem;
  height: 2rem;
  background-color: white;
  border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
}
.linkedin{
  display: flex;
  align-items: center;
  border-radius: 50%;
  background-color: #F6A400;
  border:none;
}


/* We are the the Kegon end */


/* Parallax  */

.sidepage3-main{
background-size: cover;
background-position: center;
background-attachment: fixed;
background-repeat: no-repeat;
color: white;
}

.whysafe-container{
  padding-top: 150px;
  padding-bottom: 150px;
/* background-size: cover;
background-position: center;
background-attachment: fixed;
background-repeat: no-repeat; */
color: white;
}


/* Parallax end */

/*threemost */

.threemost-container{
  background-color: #F6A400;
  background-size: cover;
background-position: center;
background-attachment: fixed;
background-repeat: no-repeat;
  height: 500px;
  padding-top: 8rem;
  padding-bottom: 7rem;
}

.swiper-important{
  transition: opacity 1s;
  -webkit-transition: all 1s;
  opacity: 1;
}

.swiper-main{
  opacity: 0;
}

/*threemost  end*/

/* History */

.history-container{
  /* padding-top: 104px;
  padding-bottom: 105px; */
  background-color: #F6A400;
}

/* History end*/

/* Sidepage */
.sidepage4-main{
  position: relative;
  /* top: 88px; */
}

/* Sidepage end */

/* Calendar */
.calendar-main{
  height: 800px;

}
.calendar-main p{
font-size: 25px;

}


.modal-contact-container input{
  background: transparent;
  border: 0;
  outline: 0;
  /* border-bottom: 1px solid white; */
}

.modal-contact-container input:focus{
  background:transparent;
  border: none;
    border-width: 0 0 2px;
    border-color:white
  }

  .modal-contact-container textarea{
  background: transparent;
  border: 0;
  outline: 0;

  /* border-bottom: 1px solid white; */
}
  .modal-contact-container textarea:focus{
    background:transparent;
    border: none;
      border-width: 0 0 2px;
      border-color:white
    }

    .modal-contact-container textarea::placeholder{
      display: flex;
      align-items: flex-end;
      justify-content: end;
    }

/* Calendar end */

select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

/* For IE10 */
select::-ms-expand {
  display: none;
}

.langbox *{
  border-radius: 50%;
}


.navbar-toggler-icon:focus {
    outline: none;
    border: 0 !important;
    box-shadow: none;
}


.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
}





.searchResults {
  margin: 10rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
  .searchResult {
      border-radius: 14px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      padding: 1rem;
      width: 500px;
  }
  .searchResult  a {
          display: flex;
          justify-content: left;
          align-items: center;
          margin: 1rem;
          color: var(--main);
  }

         
          .searchResult      svg {
              margin-left: 10px;
              transition: 0.3s all ease;
          }
      
  

          .notFoundContainer {
            display: flex;
            gap: 2rem;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 50%;
        }